import { ThemeOptions } from '@material-ui/core'
import BarChart from '@material-ui/icons/BarChart'
import Inbox from '@material-ui/icons/Inbox'

const muiTheme: ThemeOptions = {
  palette: {
    primary: { main: '#FB1F5B' },
    secondary: { main: '#F9E9E8' }
  }
}

export default {
  illustration: {
    error: 'sf-error',
    start: 'sf-start',
    faq: 'sf-faq',
    inProcess: 'sf-inProcess',
    inDevelopment: 'sf-inDevelopment',
    transferSuccess: 'sf-transfer',
    searchNotFound: 'sf-search-not-found',
    success: 'sf-success',
    init: 'sf-init'
  },
  muiTheme: muiTheme,
  colors: {
    primary: ['#FB1F5B', '#FFA6C4', '#F9E9E8', '#E5E5E5'],
    contrastPrimary: ['#FFF', '', 'black']
  },
  analytic: {
    background: '#F9F9F9',
    leftPaneStyle: {
      borderLeft: 'none',
      borderRight: '1px solid #FDE8EF',
      paddingRight: 0
    },
    menuItemStyle: {
      borderLeft: 'none',
      paddingRight: 12
    }
  },
  setting: {
    menuItemStyle: {
      borderLeft: 'none',
    }
  },
  head: {
    icon: '/smartfren/sf-favicon.svg',
    text: 'Smartfren Connex'
  },
  header: {
    logo: '/smartfren/logo-header.svg',
    logoStyle: {
      marginBottom: '20px',
      marginTop: '12px'
    },
    broadcastIconUrl: '/smartfren/icon_broadcast.svg',
    AnalyticsIcon: BarChart,
    InboxIcon: Inbox,
    styleProfileIcon: {
      background: '#FB1F5B',
      color: '#FFF'
    },
    background: '#FFFFFF',
    menuActiveColor: '#FB1F5B',
    menuColor: 'rgba(29, 29, 29, 0.4)',
    menuActiveBackground: 'rgba(251, 31, 91, 0.05)',
    menuBorderRight: '3px solid #FB1F5B',
  },
  login: {
    logoUrl: '/smartfren2/logo-login.png',
    button: {
      background: '#FB1F5B'
    }
  }
}