import { NextPageContext } from 'next'
import Router from 'next/router'

export const universalRedirect = (context: NextPageContext | null, path: string, format?: string): void => {
  if (typeof window === 'undefined' && context !== null) {
    // server-side
    context.res?.writeHead(302, { Location: path })
    context.res?.end()
  } else {
    // browser side
    if (format) {
      Router.replace(format, path)
    } else {
      Router.replace(path)
    }
  }
}

export const EditorDraft = {
  get: (roomId: string | null, messageMode: string): string | null => {
    const storageKey = `draft.${messageMode}.${roomId}`

    if (typeof window !== 'undefined' && window.localStorage.getItem(storageKey) !== null && window.localStorage.getItem(storageKey) !== 'undefined') {
      return window.localStorage.getItem(storageKey)
    } else {
      return ''
    }
  },
  save: (roomId: string | null, messageMode: string, draft: string): void => {
    const storageKey = `draft.${messageMode}.${roomId}`

    if (typeof window !== 'undefined') {
      window.localStorage.setItem(storageKey, draft)
    }
  }
}

export const middleEllipsisText = (str: string): string => {
  if (str.length > 35) {
    return `${str.substr(0, 18)}...${str.substr(str.length - 12, str.length)}`
  }
  return str
}