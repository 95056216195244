import * as Sentry from '@sentry/nextjs'
import * as CryptoJS from 'crypto-js'
import moment from 'moment'
import pkg from '../../package.json'
// import { AuthClientIds } from '../types'

const { version } = pkg

export function formatTextToSlug(text?: string): string {
  return text?.toLowerCase().trim().replace(/[^\w-]+/g,'-').replace(/[-]{2,}/g, '-') || ''
}

export function formatTextToSlugNoDash(text?: string): string {
  return text?.toLowerCase().trim().replace(/[^\w-]+/g,'').replace(/[-]{2,}/g, '') || ''
}

export function formatTextToSlugUnderscore(text?: string): string {
  return text?.toLowerCase().trim().replace(/[^\w-]+/g,'_').replace(/[-]{2,}/g, '_') || ''
}

// export const authClientIds: AuthClientIds = {
//   'password': '9e4b1582-af7d-400e-be22-d6c1eaf469a6'
// }

export const monthNames = [
  'Jan', 'Feb', 'Mar',
  'Apr', 'May', 'Jun', 'Jul',
  'Aug', 'Sep', 'Oct',
  'Nov', 'Dec'
]

export function formatDate(date: Date, type?: string): string {
  if (type === 'date') {
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  } else if (type === 'no-year') {
    return `${monthNames[date.getMonth()]} ${date.getDate()}`
  } else if (type === 'bubble-message') {
    if (new Date(date).setHours(0, 0, 0, 0) === (new Date).setHours(0, 0, 0, 0)) {
      return `${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}:${date.getSeconds().toString().length === 1 ? `0${date.getSeconds()}` : date.getSeconds()}`
    }
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}:${date.getSeconds().toString().length === 1 ? `0${date.getSeconds()}` : date.getSeconds()}`
  } else if (type === 'header-chatpane') {
    if (new Date(date).setHours(0, 0, 0, 0) === (new Date).setHours(0, 0, 0, 0)) {
      return `Today, ${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}`
    }
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}`
  }
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}`
}

export function escapeHtml(unsafe: string): string {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/\\n/g, '<br />')
}

export function getCustomerNameInitial(customerName?: string): string {
  // tokenize the word only, trim multiple white-space in the start, end, and middle of the full name
  const tokenizedCustomerName = customerName ? customerName.trim().split(/\s+/) : 'Unknown'

  if (tokenizedCustomerName.length === 1) {
    return tokenizedCustomerName[0].substring(0, 1).toUpperCase()
  } else {
    return `${tokenizedCustomerName[0].substring(0, 1).toUpperCase()}${tokenizedCustomerName[1].substring(0, 1).toUpperCase()}`
  }
}

export function makeExcerptText(text: string, limit: number): string {
  return text.length < limit ? text : `${text.substring(0, limit - 4)}...`
}

export function makeExcerptMessage(message: string): string {
  return makeExcerptText(message, 36)
}

export function notifySentry(ssr: boolean, err: unknown, errInfo: Record<string, unknown>): void {
  if (!ssr) {
    // browser
    console.log('Error in browser. Send report to Sentry...')
    Sentry.configureScope(scope => {
      scope.setTag('ssr', false)
      scope.setTag('version_web', version)
      Object.keys(errInfo).forEach((key) => {
        scope.setExtra(key, errInfo[key])
      })

      Sentry.captureException(err)
    })
  }
}

export function formatDateYYYYMMDD(date: string): string {
  const d = date.slice(0, 10).split('-')
  return `${d[0]}-${d[1]}-${d[2]}`
}

export function formatDateDDMMYYYY(date: string): string {
  const d = date.slice(0, 10).split('-')
  return `${d[2]}-${d[1]}-${d[0]}`
}

export function elapsedTimeFormatter(elapsedTime: number, type?: string): number | string {
  const ms = elapsedTime % 1000
  elapsedTime = (elapsedTime - ms) / 1000
  const sec = elapsedTime % 60
  elapsedTime = (elapsedTime - sec) / 60
  const min = elapsedTime % 60
  elapsedTime = (elapsedTime - min) / 60
  const hour = elapsedTime % 60
  if (type === 'string') {
    return `${hour > 9 ? hour : '0' + hour}:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`
  } else if (type === 'hourMinuteSecond') {
    return `${hour > 1 ? `${hour} Hours : ` : hour > 0 ? `${hour} Hour : ` : ''}${min > 1 ? `${min} Minutes : ` : min > 0 ? `${min} Minute : ` : ''}${sec > 1 ? `${sec} Seconds` : `${sec} Second`}`
  }
  return min + sec / 60
}

export function getCustomerPhoneNumber(phone?: string | null,showPhone?: boolean, type?: string | boolean): string {
  if (type === 'id' && phone) {
    return `${phone.substring(0, 5)}-xxxx-${phone.substring(phone.length - 4)}`
  }
  if(showPhone && phone){
    return `+${phone}`
  }
  return phone ? `${phone[0] === '+' ? phone.substring(0, 6) : `+${phone.substring(0, 5)}`}-xxxx-${phone.substring(phone.length - 4)}` : ''
}

export function getWaitingTime(date?: string | Date): string {
  if (!date) return ''
  const d = new Date(date)
  const current = new Date()
  const hour = Math.abs(current.getTime() - d.getTime()) / 36e5 // scientific notation for 60*60*1000
  const minute = Math.abs(current.getTime() - d.getTime()) / (60 * 1000)
  if (d.getFullYear() !== current.getFullYear()) {
    return formatDate(d, 'date')
  } else if (hour > 24) {
    return formatDate(d, 'no-year')
  } else if (hour >= 1) {
    return `${Math.floor(hour)}h ago`
  } else if (minute >= 1) {
    return `${Math.floor(minute)}m ago`
  } else {
    return '< 1m ago'
  }
}

export function formatDateSlashDMYY(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().substring(2)}`
}

export function isToday(date: Date): boolean {
  const today = new Date().setHours(0, 0, 0, 0)
  return today === date.setHours(0, 0, 0, 0)
}

export function formatDateDashYYYYMMDD(input?: Date | string): string {
  if (!input) return ''
  const date = new Date(input)
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function hoursFormatter(d: Date): string {
  return `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}`
}

export function getWaitingTimeQueue(date?: string | Date, current?: Date): string {
  if (!date || !current) return ''
  const d = new Date(date)
  const hour = Math.abs(current.getTime() - d.getTime()) / 36e5 // scientific notation for 60*60*1000
  const minute = Math.abs(current.getTime() - d.getTime()) / (60 * 1000)
  if (d.getFullYear() !== current.getFullYear()) {
    return `Waiting since ${formatDate(d, 'date')}`
  } else if (hour > 24) {
    return `Waiting since ${formatDate(d, 'no-year')}`
  } else if (hour >= 1) {
    return `Waiting for ${Math.floor(hour)} hour${Math.floor(hour) > 1 ? 's' : ''}${Math.floor(minute) % 60 > 1 ? ` ${Math.floor(minute) % 60} minutes` : `${Math.floor(minute) % 60 > 0 ? `${Math.floor(minute) % 60} minute` : ''}`}`
  } else if (minute >= 1) {
    return `Waiting for ${Math.floor(minute)} minutes`
  } else {
    return 'Just now'
  }
}

// TODO: need update data type any
export function fillData(data: Record<string, unknown>[] , periodic = 'hourly', many = 24, start = ''): any[] {
  let newData: any[] = []
  if (periodic === 'hourly') {
    const formattedData = data.map((r: any) => { // data returned from API with ISO Formatted, change it to +7 GMT
      if (r?.name) {
        const time = (parseInt(r.name.split(':')[0]) + 7) % 24
        const indoTime = time < 10 ? `0${time}:00` : `${time}:00`
        return {
          name: indoTime,
          value: r.value
        }
      }
      return
    })
    for (let i = 0; i < many; i++) {
      const currentStringTime = i < 10 ? `0${i}:00` : `${i}:00`
      const dummy = {
        name: '',
        value: 0,
      }
      dummy.name = currentStringTime
      const temp = formattedData.find((r: any) => r.name === currentStringTime) || dummy
      newData = [...newData, temp]
    }
    return newData
  } else if (periodic === 'daily') {
    const startDate = new Date(start)
    startDate.setHours(0, 0, 0, 0)
    for (let i = 0; i < Math.round(many); i++) {
      const dummy = {
        name: '',
        value: 0,
      }
      dummy.name = formatDateDashYYYYMMDD(startDate)
      const temp = data.find(r => isSameDay(new Date(r.name as string), startDate)) || dummy
      startDate.setDate(startDate.getDate() + 1)
      newData = [...newData, temp]
    }
  }
  return newData
}

export function encryptSourceId(sourceId?: string): string {
  const key = '55a51621a6648525'
  const keyutf = CryptoJS.enc.Utf8.parse(key)
  const iv = CryptoJS.enc.Base64.parse(key)
  const ciphertext = CryptoJS.AES.encrypt(sourceId || '', keyutf, { iv: iv })
  const textb64 = CryptoJS.enc.Base64.parse(ciphertext.toString())
  const eHex = textb64.toString(CryptoJS.enc.Hex)
  return eHex
}

export function decrpytSourceId(encryptedSourceId: string): string {
  const reb64 = CryptoJS.enc.Hex.parse(encryptedSourceId)
  const bytes = reb64.toString(CryptoJS.enc.Base64)
  const key = '55a51621a6648525'
  const keyutf = CryptoJS.enc.Utf8.parse(key)
  const iv = CryptoJS.enc.Base64.parse(key)
  const chipterBytes = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(bytes) } as any,
    keyutf,
    {
      iv: iv
    })
  const sourceId = CryptoJS.enc.Utf8.stringify(chipterBytes)
  return sourceId
}

export function daysBetween(startDate: Date | string, endDate: Date | string): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  return Math.abs(new Date(endDate).valueOf() - new Date(startDate).valueOf()) / millisecondsPerDay
}


export function isSameDay(firstDate: Date | string, secondDate: Date | string): boolean {
  const first = new Date(firstDate)
  const second = new Date(secondDate)
  first.setHours(0, 0, 0, 0)
  second.setHours(0, 0, 0, 0)
  return first.getTime() === second.getTime()
}

export function getTime12Hours(date: Date | string): string {
  return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export function getTime24Hours(date: Date | string): string {
  return new Date(date).toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' })
}

export const compareString = (string1?: string, string2?: string): number => {
  return (string1 || '')?.toLowerCase().localeCompare(string2?.toLowerCase() || '')
}

export function isValidPassword(str: string): boolean {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
  return re.test(str)
}

export function isTimeValid(str: string): boolean {
  const parsed = str.split(':')
  const minute = Number(parsed.pop())
  const hour = Number(parsed.pop())
  return minute < 60 && minute >= 0 && hour >= 0 && hour < 24
}

// str must follow this pattern [countrycode]phonenumber ex. 6285172240575
export function formatPhoneNumber(str: string): string {
  const match = str.match(/^(\d{2})(\d{3})(\d{4})(\d{4,})$/)

  if (match) {
    return `+${match[1]} ${match[2]}-${match[3]}-${match[4]}`
  }

  return str
}

export function validateSlug(text?: string): boolean {
  const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g
  if (text) {
    return pattern.test(text)
  }
  return true
}

