import themes from '@/data/themes'
import { IntentType } from '@/Recoil/studio/intents'
import getConfig from 'next/config'
import { CLIENT } from './getEnv'

export const primaryPallete = themes[CLIENT]?.muiTheme?.palette?.primary
export const primaryMain = primaryPallete?.['main']

const limit = process.env.NEXT_PUBLIC_MAX_UPLOAD_IMAGE_SIZE?.split(' ')
export const MAX_UPLOAD_IMG_SIZE = {
  number: parseInt(limit?.[0] || '2'),
  size: limit?.[1] || 'MB'
}


export const MainIntentType: IntentType = {
  name: 'Main',
  code: 'M',
  order: 1,
  extension: '.main',
}

export const PEIntentType: IntentType = {
  name: 'Prevent Error',
  code: 'PE',
  order: 2,
  extension: '.pe',
}

export const HSIntentType: IntentType = {
  name: 'Helpful Signifier',
  code: 'HS',
  order: 3,
  extension: '.hs',
}

export const RAIntentType: IntentType = {
  name: 'Reversal Action',
  code: 'RA',
  order: 4,
  extension: '.ra',
}

export const FallbackIntentType: IntentType = {
  name: 'Fallback',
  code: 'F',
  order: 4,
  extension: '.fallback',
}

export const MAPPING_TYPE_INTENT: Record<string, IntentType> = {
  main: MainIntentType,
  prevent_error: PEIntentType,
  helpful_signifier: HSIntentType,
  reversal_action: RAIntentType,
  fallback: FallbackIntentType
}

export const LIMIT_CHAR_QUICK_REPLY_TEXT = 25

export const GOOGLE_RECAPTCHA_KEY = getConfig()?.publicRuntimeConfig?.googleRecaptchaSiteKey

export const TEMPLATE_AUTHENTICATION = 'AUTHENTICATION_NEW'