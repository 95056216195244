import { ThemeOptions } from '@material-ui/core'

const muiTheme: ThemeOptions = {
  palette: {
    primary: { main: '#FB1F5B', '500': '#ff6e95', '100': '#f8c5d3', '50': '#fceef2', '700': '#dd164b',
      'A700': '#000' // untuk table
    },
    secondary: { main: '#F9E9E8' }
  },
  typography: {
    fontFamily: 'Trueno'
  },
  overrides: {
    MuiButton: {
      root: {
        // height: '48px',
        borderRadius: 0,
        boxShadow: '0 0 0',
        fontWeight: 600
      },
      contained: {
        boxShadow: '0 0 0',
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      }
    }

  }
}

export default {
  banner: {
    src: '/smartfren/banner.png'
  },
  illustration: {
    poweredBy: 'sf-powered-by',
    error: 'sf-error',
    start: 'sf-start',
    faq: 'sf-faq',
    inProcess: 'sf-inProcess',
    inDevelopment: 'sf-inDevelopment',
    transferSuccess: 'sf-transfer',
    searchNotFound: 'sf-search-not-found',
    success: 'sf-success',
    init: 'sf-init'
  },
  headerIcons: {
    inbox: 'sf-header-inbox',
    home: 'sf-header-home',
    campaign: 'sf-header-campaign',
    // channel: 'sf-header-channel',
    conversation: 'sf-header-conversation',
    setting: 'sf-header-setting',
    analytics: 'sf-header-analytics',
  },
  muiTheme: muiTheme,
  colors: {
    primary: ['#FB1F5B', '#FFA6C4', '#F9E9E8', '#E5E5E5'],
    contrastPrimary: ['#FFF', '', 'black']
  },
  analytic: {
    background: '#F9F9F9',
    leftPaneStyle: {
      borderLeft: 'none',
      borderRight: '1px solid #FDE8EF',
      paddingRight: 0
    },
    menuItemStyle: {
      borderLeft: 'none',
      paddingRight: 12
    }
  },
  setting: {
    menuItemStyle: {
      borderLeft: 'none',
    }
  },
  head: {
    icon: '/smartfren/favicon.ico',
    text: '1Engage'
  },
  header: {
    logo: '/smartfren/1engage-logogram.svg',
    logoStyle: {
      marginBottom: '20px',
      marginTop: '12px',
    },
    // broadcastIconUrl: '/smartfren/icon_broadcast.svg',
    // AnalyticsIcon: BarChart,
    // InboxIcon: MailOutline,
    styleProfileIcon: {
      background: '#FB1F5B',
      color: '#FFF'
    },
    background: '#FFFFFF',
    menuActiveColor: '#FB1F5B',
    menuColor: 'rgba(29, 29, 29, 0.4)',
    menuActiveBackground: 'rgba(251, 31, 91, 0.05)',
    menuBorderRight: '3px solid #FB1F5B',
  },
  login: {
    logoUrl: '/smartfren/1engage-logo.svg',
    button: {
      background: '#FB1F5B'
    }
  },
  broadcast: {
    background: 'rgb(249, 249, 249)'
  },
  dataTableTheme: {
    text: {
      primary: '#000000',
      secondary: 'black',
      disabled: 'rgba(0, 0, 0, 0.54)'
    },
    context: {
      background: '#e3f2fd',
      text: 'rgba(0, 0, 0, 0.87)',
    },
    sortFocus: {
      default: '#4087BF',
    },
    striped: {
      default: '#FAFAFA',
      text: 'rgba(0, 0, 0, 0.87)',
    },
    // highlightOnHover: {
    //   default: '#FFE9EA',
    //   text: 'black'
    // }
  },
  botStudio: {
    collapsableLogo: '/smartfren/1engage-logogram.svg',
    collapsableStyle: {
      width: '30px'
    },
    expandedLogo: '/smartfren/1engage-logo.svg',
    expandedStyle: {
      width: '100px'
    }
  },
  componentStyle: {
    borderRadius: undefined
  },
  instagramRedirect: {
    logoUrl: '/smartfren/1engage-logo.svg'
  }
}