import { useState, useContext, createContext, Dispatch, SetStateAction, FC } from 'react'
import { ApplicationType } from '../../types'

const ApplicationContext = createContext<ApplicationType | null>(null)
ApplicationContext.displayName = 'ApplicationContext'
const ApplicationSetterContext = createContext<Dispatch<SetStateAction<ApplicationType | null>>>(() => {})
ApplicationSetterContext.displayName = 'ApplicationSetterContext'

const ApplicationContextProvider: FC<{ value?: ApplicationType }> = ({ value, ...props }) => {
  const [application, setApplication] = useState<ApplicationType | null>(value || null)

  return (
    <ApplicationContext.Provider value={application}>
      <ApplicationSetterContext.Provider value={setApplication}>
        {props.children}
      </ApplicationSetterContext.Provider>
    </ApplicationContext.Provider>
  )
}


export const useApplication: () => [ApplicationType | null, Dispatch<SetStateAction<ApplicationType | null>>] = () => {
  const valueContext = useContext(ApplicationContext)
  const setterContext = useContext(ApplicationSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useApplication must be used on child component of ApplicationContextProvider component'
  }

  return [valueContext, setterContext]
}

export default ApplicationContextProvider