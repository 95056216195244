import { io, Socket } from 'socket.io-client'
import PubSub from 'pubsub-js'
import { notifySentry } from '.'
import getEnv from './getEnv'
import { DefaultEventsMap } from 'socket.io-client/build/typed-events'

export const SOCKET_INCOMING_MESSAGE = 'SOCKET_INCOMING_MESSAGE'
export const SOCKET_ROOM_CREATED = 'SOCKET_ROOM_CREATED'
export const SOCKET_TICKET_CREATED_IN_ROOM = 'SOCKET_TICKET_CREATED_IN_ROOM'
export const SOCKET_TICKET_SOLVED = 'SOCKET_TICKET_SOLVED'
export const SOCKET_TICKET_CREATED_IN_QUEUE = 'SOCKET_TICKET_CREATED_IN_QUEUE'
export const SOCKET_TICKET_ASSIGNED = 'SOCKET_TICKET_ASSIGNED'
export const SOCKET_FORCE_RELOAD = 'SOCKET_FORCE_RELOAD'
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'
export const SOCKET_RECONNECT = 'SOCKET_RECONNECT'

function SingletonLayanSocket() {
  let client: Socket<DefaultEventsMap, DefaultEventsMap>

  const initialize = (socketServerURL: string, jwtToken: string, applicationSlug: string) => {
    if (!client?.connected) { // need to be silent, can't afford to throw error on second initialization
      client = io(socketServerURL, {
        withCredentials: true,
        query: {
          applicationSlug,
          token: jwtToken
        },
        auth: {
          token: jwtToken
        },
        reconnectionAttempts: 10,
        reconnectionDelay: 1000
      })

      client.on('error', (error: any) => {
        console.log('LayanSocket error: ', error)

        if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
          notifySentry(typeof window === 'undefined', error, {})
        }

        // force reloading
        window.location.reload()
      })

      client.on('connect', () => {
        PubSub.publish(SOCKET_RECONNECT)
        console.info('Connection connected')
      })

      client.on('reconnect', (attemptNumber: number) => {
        console.log('Connection restored. Reconnection attempt: ', attemptNumber)
      })

      client.on('reconnect_error', (error: any) => {
        console.log('Reconnect attempt failed. Report to sentry.', error)

        // if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        //   notifySentry(typeof window === 'undefined', error, {})
        // }
      })

      client.on('connect_error', (error: any) => {
        console.log('Connect failed. Report to sentry.', error)

        // if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        //   notifySentry(typeof window === 'undefined', error, {})
        // }
      })

      client.on('reconnect_failed', () => {
        console.log('Reconnection failed. Report to sentry and refresh page.')

        // if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        //   notifySentry(typeof window === 'undefined', { error: 'Reconnection attempt failed' }, {})
        // }

        // force reloading
        window.location.reload()
      })

      client.on('disconnect', (reason: string) => {
        console.log('Disconnected from server')
        PubSub.publish(SOCKET_DISCONNECT, reason)
        if (reason === 'io server disconnect') {
          console.log('It was the server which close it. Trying to reconnect...')
          // the disconnection was initiated by the server, reconnect manually
          client?.connect()
        }
      })

      client.on('message',
        (data: IncomingMessageSocketEvent) => {
          console.log('Got socket event: ', `SOCKET_INCOMING_MESSAGE_${data.message.ticket._id}`)
          console.log(data)
          try {
            PubSub.publish(`SOCKET_INCOMING_MESSAGE_${data.message.ticket._id}`, data)
            PubSub.publish('SOCKET_INCOMING_MESSAGE', data)
          } catch (e) {
            console.log('error in socket', e)
          }

        }
      )

      client.on('room created', (data: RoomCreatedSocketEvent) => {
        console.log('Got socket event: ', SOCKET_ROOM_CREATED)
        console.log(data)
        PubSub.publish(SOCKET_ROOM_CREATED, data)
      })

      client.on('new ticket created in room', (data: TicketCreatedSocketEvent) => {
        console.log('Got socket event: ', SOCKET_TICKET_CREATED_IN_ROOM)
        console.log(data)
        PubSub.publish(SOCKET_TICKET_CREATED_IN_ROOM, data)
      })

      client.on('ticket solved',
        (data: TicketSolvedSocketEvent) => {
          console.log('Got socket event: ', `SOCKET_TICKET_SOLVED_${data.ticket._id}`)
          console.log(data)
          PubSub.publish(`SOCKET_TICKET_SOLVED_${data.ticket._id}`, data)
          PubSub.publish('SOCKET_TICKET_SOLVED', data)
        }
      )

      client.on('new ticket created in queue', (data: TicketCreatedSocketEvent) => {
        console.log('Got socket event: ', SOCKET_TICKET_CREATED_IN_QUEUE)
        console.log(data)
        PubSub.publish(SOCKET_TICKET_CREATED_IN_QUEUE, data)
      })

      client.on('ticket assigned', (data: TicketCreatedSocketEvent) => {
        console.log('Got socket event: ', SOCKET_TICKET_ASSIGNED)
        console.log(data)
        PubSub.publish(SOCKET_TICKET_ASSIGNED, data)
      })

      client.on('force reload', () => {
        console.log('Got socket event: ', SOCKET_FORCE_RELOAD)
        PubSub.publish(SOCKET_FORCE_RELOAD, {})
      })
    }

    return client
  }

  const publishSendMessage = (messageObject: SendMessageSocketEmit) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    client.emit('reply ticket', { message: { ...messageObject } }, (data: any) => {
      if (data.error) {
        console.log('Something went wrong on the server... Report to sentry')

        if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
          notifySentry(typeof window === 'undefined', data.error, {})
        }

        // retry
        publishSendMessage({ ...messageObject })
      }
    })
  }

  /**
   * @param {string} ticketId
   * @param {(key: string, incomingMessage: IncomingMessageSocketEvent) => void} subscriberFunc
   */
  const subscribeIncomingMessage = (ticketId: string, subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(`SOCKET_INCOMING_MESSAGE_${ticketId}`, subscriberFunc)
  }

  /**
   *
   * @param {(key: string, roomCreated: RoomCreatedSocketEvent) => void} subscriberFunc
   */
  const subscribeRoomCreated = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(SOCKET_ROOM_CREATED, subscriberFunc)
  }

  /**
   *
   * @param {(key: string, ticketCreated: TicketCreatedSocketEvent) => void} subscriberFunc
   */
  const subscribeTicketCreated = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(SOCKET_TICKET_CREATED_IN_ROOM, subscriberFunc)
  }

  /**
   * @param {string} ticketId
   * @param {(key: string, ticketSolved: TicketSolvedSocketEvent) => void} subscriberFunc
   */
  const subscribeTicketSolved = (ticketId: string, subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(`SOCKET_TICKET_SOLVED_${ticketId}`, subscriberFunc)
  }

  /**
   * @param {(key: string, ticketSolved: TicketSolvedSocketEvent) => void} subscriberFunc
   */
  const subscribeAllTicketSolved = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe('SOCKET_TICKET_SOLVED', subscriberFunc)
  }

  /**
   *
   * @param {(key: string, ticketCreated: TicketCreatedSocketEvent) => void} subscriberFunc
   */
  const subscribeTicketCreatedInQueue = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(SOCKET_TICKET_CREATED_IN_QUEUE, subscriberFunc)
  }

  /**
   *
   * @param {(key: string, ticketCreated: TicketCreatedSocketEvent) => void} subscriberFunc
   */
  const subscribeTicketAssigned = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(SOCKET_TICKET_ASSIGNED, subscriberFunc)
  }

  const subscribeForceReload = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe(SOCKET_FORCE_RELOAD, subscriberFunc)
  }

  /**
   * @param {(key: string, incomingMessage: IncomingMessageSocketEvent) => void} subscriberFunc
   */
  const subscribeAllIncomingMessage = (subscriberFunc: (_param1: any, _param2: any) => any) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.subscribe('SOCKET_INCOMING_MESSAGE', subscriberFunc)
  }

  const unsubscribeEvent = (subscriberToken: string) => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return PubSub.unsubscribe(subscriberToken)
  }

  const open = () => {
    if (!client) {
      throw 'Socket client not initialized'
    }

    return client.open()
  }

  const close = () => {
    if (!client) {
      throw 'Socket client not initialized'
    }
    return client.disconnect()
  }

  const isInitialized = () => {
    console.log('===== client connected', client?.connected)
    return client?.connected
  }

  const subscribeDisconnect = (subscriberFunc: (_param1: any, _param2: any) => any) =>{
    return PubSub.subscribe(SOCKET_DISCONNECT, subscriberFunc)
  }

  const subscribeReconnect = (subscriberFunc: (_param1: any, _param2: any) => any) =>{
    return PubSub.subscribe(SOCKET_RECONNECT, subscriberFunc)
  }

  return {
    open,
    close,
    initialize,
    isInitialized,
    publishSendMessage,
    subscribeIncomingMessage,
    subscribeRoomCreated,
    subscribeTicketCreated,
    subscribeTicketSolved,
    subscribeAllTicketSolved,
    subscribeTicketCreatedInQueue,
    subscribeTicketAssigned,
    subscribeForceReload,
    subscribeAllIncomingMessage,
    subscribeDisconnect,
    subscribeReconnect,
    unsubscribeIncomingMessage: (incomingMessageSubscriberToken: string) => unsubscribeEvent(incomingMessageSubscriberToken),
    unsubscribeRoomCreated: (roomCreatedSubscriberToken: string) => unsubscribeEvent(roomCreatedSubscriberToken),
    unsubscribeTicketCreated: (ticketCreatedSubscriberToken: string) => unsubscribeEvent(ticketCreatedSubscriberToken),
    unsubscribeTicketSolved: (ticketSolvedSubscriberToken: string) => unsubscribeEvent(ticketSolvedSubscriberToken),
    unsubscribeAllTicketSolved: (allTicketSolvedSubscriberToken: string) => unsubscribeEvent(allTicketSolvedSubscriberToken),
    unsubscribeTicketCreatedInQueue: (ticketCreatedInQueueSubscriberToken: string) => unsubscribeEvent(ticketCreatedInQueueSubscriberToken),
    unsubscribeTicketAssigned: (ticketAssignedSubscriberToken: string) => unsubscribeEvent(ticketAssignedSubscriberToken),
    unsubscribeForceReload: (forceReloadSubscriberToken: string) => unsubscribeEvent(forceReloadSubscriberToken),
    unsubscribeAllIncomingMessage: (allIncomingMessageSubscriberToken: string) => unsubscribeEvent(allIncomingMessageSubscriberToken),
    unsubscribeDisconnect: (disconnectSubscriberToken: string) => unsubscribeEvent(disconnectSubscriberToken),
    unsubscribeReconnect: (reconnectSubscriberToken: string) => unsubscribeEvent(reconnectSubscriberToken),
  }
}

const LayanSocket = SingletonLayanSocket()

export default LayanSocket

export type IncomingMessageSocketEvent = {
  message: {
    _id: string,
    ticketId: string,
    type: 'byUser' | 'info' | 'reminder',
    isPublic: boolean,
    content: {
      type: 'text' | 'image' | 'document' | 'voice' | 'video',
      text?: string,
      attachment?: {
        url: string,
        mimeType: string
      }
    },
    user?: {
      _id: string,
      name: string,
      username: string,
      email: string,
      password: string,
      token: string,
      role: 'agent' | 'admin' | 'customer',
      isAvailable?: boolean,
      socketId?: string,
      phone?: string
    },
    ticket: {
      _id: string,
      number: number,
      roomId: string,
      assignedTo?: string,
      solvedBy?: string,
      solvedAt?: Date,
      room: {
        _id: string,
        applicationId: string,
        title?: string,
        fields?: { fieldId: string, value: string }[],
        initiatedBy: {
          _id: string,
          name: string,
          username: string,
          email: string,
          password: string,
          token: string,
          role: 'agent' | 'admin' | 'customer',
          isAvailable?: boolean,
          socketId?: string,
          phone?: string
        }
      }
    }
  }
}


export type TicketCreatedSocketEvent = {
  room: {
    _id: string,
    applicationId: string,
    assignedTo: string,
    title?: string,
    fields?: { fieldId: string, value: string }[],
    initiatedBy: {
      _id: string,
      name: string,
      username: string,
      email: string,
      password: string,
      token: string,
      role: 'agent' | 'admin' | 'customer',
      isAvailable?: boolean,
      socketId?: string,
      phone?: string
    },
    latestMessage: {
      ticketId: string,
      type: 'byUser' | 'info' | 'reminder',
      isPublic: boolean,
      createdAt: string,
      updatedAt: string,
      content: {
        type: 'text' | 'image' | 'document' | 'voice' | 'video',
        text?: string,
        attachment?: {
          url: string,
          mimeType: string
        }
      },
      user?: {
        _id: string,
        name: string,
        username: string,
        email: string,
        password: string,
        token: string,
        role: 'agent' | 'admin' | 'customer',
        isAvailable?: boolean,
        socketId?: string,
        phone?: string
      }
    }
  }
}

export type TicketSolvedSocketEvent = {
  ticket: {
    _id: string,
    number: number,
    roomId: string,
    assignedTo?: string,
    solvedBy?: string,
    solvedAt?: Date,
    room: {
      _id: string,
      applicationId: string,
      title?: string,
      fields?: { fieldId: string, value: string }[],
      initiatedBy: string
    }
  }
}

export type RoomCreatedSocketEvent = {
  room: {
    _id: string,
    applicationId: string,
    title?: string,
    fields?: { fieldId: string, value: string }[],
    initiatedBy: string
  }
}

export type SendMessageSocketEmit = {
  ticketId: string,
  type?: 'byUser' | 'info' | 'reminder',
  isPublic?: boolean,
  content: {
    type: 'text' | 'image' | 'document' | 'voice' | 'video',
    text?: string,
    attachment?: {
      url: string,
      mimeType: string
    }
  }
}
