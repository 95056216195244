import { useState, useContext, createContext, FC, Dispatch, SetStateAction } from 'react'

type UnreadNotificationState = { [key: string]: boolean }

const UnreadNotificationContext = createContext<UnreadNotificationState>({})
UnreadNotificationContext.displayName = 'UnreadNotificationContext'
const UnreadNotificationSetterContext = createContext<Dispatch<SetStateAction<UnreadNotificationState>>>(() => {})
UnreadNotificationSetterContext.displayName = 'UnreadNotificationSetterContext'


const UnreadNotificationProvider: FC = props => {
  const [unreadNotification, setUnreadNotification] = useState<UnreadNotificationState>({})

  return (
    <UnreadNotificationContext.Provider value={unreadNotification}>
      <UnreadNotificationSetterContext.Provider value={setUnreadNotification}>
        {props.children}
      </UnreadNotificationSetterContext.Provider>
    </UnreadNotificationContext.Provider>
  )
}

export function useUnreadNotification(): [UnreadNotificationState, Dispatch<SetStateAction<UnreadNotificationState>>] {
  const valueContext = useContext(UnreadNotificationContext)
  const setterContext = useContext(UnreadNotificationSetterContext)

  if (valueContext === undefined || valueContext === undefined) {
    throw 'useUnreadNotification must be used on child component of UnreadNotificationProvider component'
  }

  return [valueContext, setterContext]
}

export default UnreadNotificationProvider