import { notifySentry } from '.'
import Request from '@bahasa-ai/plugins-request'
import getEnv from './getEnv'
import { AxiosResponse, Method, ResponseType } from 'axios'
import { UserType } from '../types'

type APICallType = <Data = any>(method: Method, path: string, payload?: any, authorization?: string, headers?: any, responseType?: ResponseType) => Promise<{
  status: number,
  data?: Data,
  error?: any,
  headers?: any
}>
// Layan API singleton object
// Need to be initialized in the root-top component
// Set base URL before using it, otherwise will throw error
function SingletonLayanAnalyticsAPI() {
  let baseURL: string | null = null
  let config = {
    ssr: false
  }

  const APICall: APICallType = async (method, path, payload, authorization, headers, responseType) => {
    if (!baseURL) {
      throw 'You need to call setBaseURL(baseURL: string) first before using LayanAPI'
    }

    let response: null | AxiosResponse<any> | undefined = null
    try {
      const req = new Request(baseURL, {
        ...headers,
        'Fondasi-Referrer': 'Web',
        Authorization: authorization
      }, {
        timeout: 30000,
        responseType: responseType
      }, {
        shouldResetTimeout: true,
        returnData: false,
        retries: 5,
        retryDelay: () => 2000
      })
      if (method === 'get') {
        response = await req.get(path)
      } else if (method === 'post') {
        response = await req.post(path, payload)
      } else if (method === 'put') {
        response = await req.put(path, payload)
      } else if (method === 'patch') {
        response = await req.patch(path, payload)
      } else if (method === 'delete') {
        response = await req.delete(path, payload)
      }
      console.log('Req: ', `${method} ${baseURL}${path} ${JSON.stringify(payload)}`)
      response ? console.log({ status: response.status, data: response.data, headers: response.headers }) : console.log('response undefined')

      // something weird happen, non 200-ish API response
      if (!response) {
        return { status: 408, data: 'Timeout 30000ms', error: 'Timeout' }
      } else if (response.status < 200 || response.status >= 300) {
        if (response.status >= 500) {
          throw new Error(`[${response.status}]: ${method.toString().toUpperCase()} ${baseURL}${path}`)
        }
        return { status: response.status, data: response.data, error: response.data.error }
      }
    } catch (e) {
      if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        notifySentry(typeof window === 'undefined', e, {
          method,
          url: `${baseURL}${path}`,
          headers: {
            ...headers,
            Authorization: authorization
          },
          data: payload
        })
      }

      return { status: response && response.status || 500, data: 'Network Error', error: response && response.data.error }
    }

    return { status: response.status, data: response.data, headers: response.headers }
  }

  const setBaseURL = (newBaseURL: string, initConfig?: Record<string, any>) => {
    baseURL = newBaseURL

    if (initConfig !== undefined) {
      config = {
        ...Object.keys(config)
          .reduce((acc, c) => initConfig[c] !== undefined ? { ...acc, [c]: initConfig[c] } : { ...acc }, { ...config })
      }
    }

    return baseURL
  }

  const getBaseURL = () => baseURL

  const login = async (username: string, password: string) => {
    return await APICall<{ user: UserType, jwt: any }>('post', '/requestToken', { username, password })
  }

  const getResolutionTimeGlobal = async (startedAt: string, endedAt: string, groupId: number | null | string, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalResolutionTime?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getResolutionTimePersonal = async (startedAt: string, endedAt: string, listUserId: string[], periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('post', '/personalResolutionTime', { startedAt, endedAt, listUserId, periodic }, `Bearer ${jwtToken}`)
  }

  const getFirstResponseTimeGlobal = async (startedAt: string, endedAt: string, groupId: number | string | null, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalFirstResponseTime?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getFirstResponseTimePersonal = async (startedAt: string, endedAt: string, listUserId: string[], periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('post', '/personalFirstResponseTime', { startedAt, endedAt, periodic, listUserId }, `Bearer ${jwtToken}`)
  }

  const getTicketStatisticGlobal = async (startedAt: string, endedAt: string, agentId: string, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalTicketStatistic?startedAt=${startedAt}&endedAt=${endedAt}&agentId=${agentId}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getTicketStatisticPersonal = async (startedAt: string, endedAt: string, listUserId: number[], periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('post', '/personalTicketStatistic', { startedAt, endedAt, listUserId, periodic }, `Bearer ${jwtToken}`)
  }

  const getResponseTimeGlobal = async (startedAt: string, endedAt: string, groupId: number | string | null, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalResponseTime?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getResponseTimePersonal = async (startedAt: string, endedAt: string, listUserId: string[], periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('post', '/personalResponseTime', { startedAt, endedAt, listUserId, periodic }, `Bearer ${jwtToken}`)
  }

  const getQueueTimeGlobal = async (startedAt: string, endedAt: string, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalQueueingTime?startedAt=${startedAt}&endedAt=${endedAt}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getTicketAnalyticsData = async (ticketId: string, userId: string, jwtToken: string | undefined) => {
    return await APICall('get', `/ticketAnalyticMetrics?ticketId=${ticketId}&userId=${userId}`, {}, `Bearer ${jwtToken}`)
  }

  const getTotalUser = async (startedAt: string, endedAt: string, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/totalUser?startedAt=${startedAt}&endedAt=${endedAt}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getGlobalThreadInQueueStatistic = async (startedAt: string | undefined, endedAt: string | undefined, periodic: string | null, groupId: number | string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalThreadInQueueStatistic?startedAt=${startedAt}&endedAt=${endedAt}${periodic ? `&periodic=${periodic}` : ''}${groupId ? `&groupId=${groupId}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getGlobalThreadInQueue = async (startedAt: string, endedAt: string, groupId: number | string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalThreadInQueue?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getMAU = async (startedAt: string, endedAt: string, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/maus?startedAt=${startedAt}&endedAt=${endedAt}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getActiveTicketDistribution = async (startedAt: string, endedAt: string, groupId: number | string | null | undefined, jwtToken: string | undefined) => {
    return await APICall('get', `/assignedTicketDistribution?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getEfficiencyScore = async (startedAt: string, endedAt: string, jwtToken: string | undefined) => {
    return await APICall('get', `/efficiencyScore?startedAt=${startedAt}&endedAt=${endedAt}`, {}, `Bearer ${jwtToken}`)
  }

  const getTotalConversation = async (startedAt: string, endedAt: string, jwtToken: string | undefined) => {
    return await APICall('get', `/totalConversation?startedAt=${startedAt}&endedAt=${endedAt}`, {}, `Bearer ${jwtToken}`)
  }

  const getTotalThread = async (startedAt: string, endedAt: string, groupId: number | string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/totalThread?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getSolvedTotalThread = async (startedAt: string, endedAt: string, groupId: number | string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/totalSolvedThread?startedAt=${startedAt}&endedAt=${endedAt}${groupId ? `&groupId=${groupId}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getTaggingStatistic = async (startedAt: string | undefined, endedAt: string | undefined, listTagId: string[], groupId: number | string | null, jwtToken: string | undefined) => {
    return await APICall('post', '/globalTaggingStatistic', { startedAt, endedAt, listTagId, groupId }, `Bearer ${jwtToken}`)
  }

  const getGlobalCSATStatistic = async (startedAt: string, endedAt: string, listTag: string | null, assignedTo: string | null, periodic: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/globalCsatStatistic?startedAt=${startedAt}&endedAt=${endedAt}${listTag ? `&tag=${listTag}` : ''}${assignedTo ? `&assignedTo=${assignedTo}` : ''}${periodic ? `&periodic=${periodic}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getCacheDuration = async (jwtToken: string | undefined) => {
    return await APICall('get', '/cacheDuration', {}, `Bearer ${jwtToken}`)
  }

  return {
    getBaseURL,
    setBaseURL,
    login,
    getFirstResponseTimeGlobal,
    getFirstResponseTimePersonal,
    getResolutionTimeGlobal,
    getResolutionTimePersonal,
    getResponseTimeGlobal,
    getResponseTimePersonal,
    getQueueTimeGlobal,
    getTicketStatisticGlobal,
    getTicketAnalyticsData,
    getTicketStatisticPersonal,
    getTotalUser,
    getGlobalThreadInQueueStatistic,
    getMAU,
    getActiveTicketDistribution,
    getEfficiencyScore,
    getTotalConversation,
    getTotalThread,
    getSolvedTotalThread,
    getTaggingStatistic,
    getGlobalCSATStatistic,
    getCacheDuration,
    getGlobalThreadInQueue,
  }
}

const LayanAnalyticsAPI = SingletonLayanAnalyticsAPI()

export default LayanAnalyticsAPI