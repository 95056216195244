import NextError from 'next/error'
import { FC } from 'react'

interface Props {
  error: Error
}
const FallbackError: FC<Props> = ({ error, children }) => {
  return (
    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
      <NextError statusCode={500} title={`${error.name}: ${error.message}` as string} >
        {children}
      </NextError>
    </div>
  )
}

export default FallbackError
