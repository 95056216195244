import * as Sentry from '@sentry/nextjs'

// TODO
export const beforeSendToSentry = (event: Sentry.Event, _hint: Sentry.EventHint): any => {
  if (
    event.exception &&
    event.exception.values
  ) {
    const result = event.exception.values.filter(r =>
      r.value?.includes('ResizeObserver')
      || r.value?.includes('NotAllowedError: play()'))
    if(result.length > 0) {
      return null
    }
  }
  return event
}
