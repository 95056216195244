import { universalRedirect } from '@/components/utils'
import { removeCookies } from '@/components/utils/Auth'
import Request from '@bahasa-ai/plugins-request'
import JSCookie from 'js-cookie'
import { notifySentry } from '.'
import pkg from '../../package.json'
import { UserType } from '../types'
import getEnv from './getEnv'

// This singleton object used for API v2

const { version } = pkg
function SingletonFondasiAPI() {
  let baseURL: string | null = null
  let token: string | null = null
  let config = {
    ssr: false
  }

  const getToken = () => {
    return `Bearer ${JSCookie.get('authorization') || token}`
  }

  const APICall = async (method: string, path: string, payload?: any, otherParams?: Request['appConfig']) => {
    if (!baseURL) {
      throw 'You need to call setBaseURL(baseURL: string) first before using Fondasi API'
    }
    const { headers, ...params } = otherParams || {}

    let response: any = null
    try {
      const req = new Request(baseURL, {
        Authorization: getToken(),
        'Fondasi-Referrer': 'Web',
        'webapp-version': version,
        ...headers
      }, {
        timeout: 30000,
        ...params
      }, {
        shouldResetTimeout: true,
        returnData: false,
        retries: 5,
        retryDelay: () => 2000
      })
      if (method === 'get') {
        response = await req.get(path)
      } else if (method === 'post') {
        response = await req.post(path, payload)
      } else if (method === 'put') {
        response = await req.put(path, payload)
      } else if (method === 'patch') {
        response = await req.patch(path, payload)
      } else if (method === 'delete') {
        response = await req.delete(path, payload)
      }
      // something weird happen, non 200-ish API response
      if (!response) {
        console.log('Req: ', `${method} ${baseURL}${path} ${JSON.stringify(payload)}`)
        response ? console.log({ status: response.status, data: response.data, headers: response.headers }) : console.log('response undefined')

        return { status: 408, data: 'Timeout 30000ms', error: 'Timeout' }
      } else if (response.status < 200 || response.status >= 300) {
        if (response.status >= 500) {
          throw new Error(`[${response.status}]: ${method.toString().toUpperCase()} ${baseURL}${path}`)
        }
        return { status: response.status, data: response.data, error: response && (response.data.error?.message || response.data.error?.msg || response.data.error || response.data || 'Network Error') }
      }
    } catch (e) {
      if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        console.log('Req: ', `${method} ${baseURL}${path} ${JSON.stringify(payload)}`)
        response ? console.log({ status: response.status, data: response.data, headers: response.headers }) : console.log('response undefined')
        notifySentry(typeof window === 'undefined', e, {
          method,
          url: `${baseURL}${path}`,
          headers: {
            ...otherParams?.headers,
            Authorization: token
          },
          data: payload
        })
      }
      if (path !=='/requestToken') {
        if (response?.status === 401) {
          removeCookies()
          universalRedirect(null, '/login')
        }
      }

      return { status: response && response.status || 500, data: 'Network Error', error: response && (response.data.error?.message || response.data.error?.msg || response.data.error || response.data || 'Network Error') }
    }

    return { status: response.status, data: response.data, headers: response.headers }
  }

  const setBaseURL = (newBaseURL: string, initConfig?: any) => {
    baseURL = newBaseURL

    if (initConfig !== undefined) {
      config = {
        ...Object.keys(config)
          .reduce((acc, c) => initConfig[c] !== undefined ? { ...acc, [c]: initConfig[c] } : { ...acc }, { ...config })
      }
    }

    return baseURL
  }

  const getBaseURL = () => baseURL

  const setToken = (newToken: string) => {
    token = newToken
  }

  const getApplicationDetail = async () => {
    return await APICall('get', '/application')
  }

  const getAgentStatuses = async () => {
    return await APICall('get', '/statuses')
  }

  const createAgentStatus = async (payload: {name: string, isAvailable: boolean}) => {
    return await APICall('post', '/status', { status: { ...payload } })
  }

  const patchAgentStatus = async (statusId: number, payload: {name: string, isAvailable: boolean}) => {
    return await APICall('patch', `/status/${statusId}`, { status: { ...payload } })
  }

  const deleteAgentStatus = async (statusId: number) => {
    return await APICall('delete', `/status/${statusId}`)
  }

  const getMacros = async () => {
    return await APICall('get', '/templateMessages')
  }

  const createMacro = async (payload: { title: string, message: string }) => {
    return await APICall('post', '/templateMessage', { templateMessage: { ...payload } })
  }

  const patchMacro = async (macroId: number, payload: { title: string, message: string }) => {
    return await APICall('patch', `/templateMessage/${macroId}`, { templateMessage: { ...payload } })
  }

  const deleteMacro = async (macroId: number) => {
    return await APICall('delete', `/templateMessage/${macroId}`, {})
  }

  const getTicketFields = async () => {
    return await APICall('get', '/fields', {})
  }

  const createUserGroup = async (payload: { name: string }) => {
    return await APICall('post', '/group', { group: { ...payload } })
  }

  const patchUserGroup = async (groupId: string | number, payload: { name: string }) => {
    return await APICall('get', `/group/${groupId}`, { group: { ...payload } })
  }

  const deleteUserGroup = async (groupId: string | number) => {
    return await APICall('delete', `/group/${groupId}`, {})
  }

  const getChannels = async () => {
    return await APICall('get', '/bots')
  }

  const getTagGroups = async () => {
    return await APICall('get', '/tagGroupsOnly')
  }

  const getAgents = async () => {
    return await APICall('get', '/users?role=admin,agent&isAvailable=true')
  }

  const getUsersByRole = async (role: string, limited?: boolean, showDeleted?: boolean) => {
    return await APICall('get', `/users?${role ? `role=${role}` : '' }${limited ? `&limited=${limited}` : '' }${showDeleted ? '&showDeletedUser=true' : ''}`)
  }

  const importUsers = async (file: FormData) => {
    return await APICall('post', '/bulkCreateUsers', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const changePassword = async (payload: { oldPassword: string, newPassword: string }) => {
    return await APICall('post', '/changePassword', { ...payload })
  }

  const createUser = async (payload: any) => {
    return await APICall('post', '/user', { user: { ...payload } })
  }

  const exportUsers = async (type?: 'csv' | 'xlsx') => {
    return await APICall('get', `/exportUsers?type=${type || 'csv'}`, {}, { responseType: 'blob' })
  }

  const getUserDetails = async () => {
    return await APICall('get', '/user')
  }

  const updateUser = async (userId: string | number, payload: Partial<UserType>) => {
    return await APICall('patch', `/user/${userId}`, { user: { ...payload } })
  }

  const patchSelfUser = async (userData: Partial<UserType>) => {
    return await APICall('patch', '/user', { user: { ...userData } })
  }

  const getUserById = async (id: string | number) => {
    return await APICall('get', `/user/${id}`)
  }

  const getUserGroupById = async (groupId: string | number) => {
    return await APICall('get', `/group/${groupId}`)
  }

  const getUserGroups = async () => {
    return await APICall('get', '/groups')
  }

  const setAgentStatus = async (isOnline: boolean, name: string) => {
    return await APICall('patch', '/user', { user: { isAvailable: isOnline, status: name } })
  }

  const deleteUser = async (userId: number | string) => {
    return await APICall('delete', `/user/${userId}`)
  }

  const getUsersByRoleAndShowDeleted = async (role: string, limited?: boolean) => {
    return await APICall('get', `/users?${role ? `role=${role}` : '' }${limited ? `&limited=${limited}` : '' }&showDeletedUser=true`)
  }

  const restoreUser = async (userId: number | string) => {
    return await APICall('patch', `/user/${userId}/restore`)
  }

  const login = async (username: string, password: string) => {
    return await APICall('post', '/requestToken', { username, password })
  }

  const pushMessage = async (payload: any) => {
    return await APICall('post', '/pushTemplate/push', { pushData: payload })
  }

  return {
    pushMessage,
    login,
    restoreUser,
    getUsersByRoleAndShowDeleted,
    deleteUser,
    setAgentStatus,
    getUserGroups,
    getUserGroupById,
    getUserById,
    patchSelfUser,
    updateUser,
    getUserDetails,
    exportUsers,
    createUser,
    changePassword,
    importUsers,
    getUsersByRole,
    getAgents,
    getTagGroups,
    getChannels,
    patchUserGroup,
    deleteUserGroup,
    createUserGroup,
    getTicketFields,
    getMacros,
    createMacro,
    patchMacro,
    deleteMacro,
    getAgentStatuses,
    createAgentStatus,
    patchAgentStatus,
    deleteAgentStatus,
    getApplicationDetail,
    setToken,
    setBaseURL,
    getBaseURL
  }

}

const FondasiAPI = SingletonFondasiAPI()

export default FondasiAPI