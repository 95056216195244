import { Theme } from '.'

export default {
  banner: {
    src: '/bahasa/banner.jpg'
  },
  head: {
    icon: '/bahasa/favicon.ico',
    text: 'Fondasi'
  },
  muiTheme: {
    palette: {
      primary: { main: '#2196F3', '100': '#baddfa', '50': '#EDF3F7', 700: 'rgb(64, 135, 191)' },
      secondary: { main: '#2591FF' },
      warning: { main: '#ff9800' }
    }
  },
  botStudio: {
    collapsableLogo: '/fondasi-logo-square.svg',
    collapsableStyle: {
      width: '40px'
    },
    expandedLogo: '/fondasi-logo-square.svg',
    expandedStyle: {
      width: '30px'
    }
  },
  componentStyle: {
    borderRadius: {
      xs: '5px',
      sm: '10px',
    }
  }
} as Theme