import * as Sentry from '@sentry/nextjs'
import getEnv from './src/utils/getEnv'
import { beforeSendToSentry } from './src/utils/Sentry'


const SENTRY_DSN = getEnv['layanWebSentryDSN']
const ENVIRONMENT = getEnv['environment']

// init Sentry for monitoring and reporting

if (SENTRY_DSN && ENVIRONMENT !== 'local') {
  console.log('init sentry client')
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    normalizeDepth: 10,
    beforeSend: (event, hint) => beforeSendToSentry(event, hint)
  })
}