import { SvgIconTypeMap, ThemeOptions } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { CSSProperties } from 'react'
import { Theme as DTTheme } from 'react-data-table-component'
import bahasa from './bahasa'
import dam from './dam'
import dana from './dana'
import grahacom from './grahacom'
import smartfren from './smartfren'
import smartfren2 from './smartfren2'

const themes: { [key: string]: Theme } = {
  grahacom: grahacom,
  dana: dana,
  bahasa: bahasa,
  smartfren2: smartfren2,
  dam: dam,
  smartfren: smartfren
}

export default themes

export type Theme = {
  banner?: {
    src: string
  },
  headerIcons?: {
    contact?: string,
    report?: string,
    usageCredit?: string,
    dataRoom?: string,
    faq?: string,
    inbox?: string,
    home?: string,
    campaign?: string,
    channel?: string,
    conversation?: string,
    setting?: string,
    analytics?: string,
    botStudio?: string
  },
  illustration?: {
    poweredBy?: string,
    error?: string,
    start?: string,
    faq?: string,
    inProcess?: string,
    inDevelopment?: string,
    transferSuccess?: string,
    success?: string,
    searchNotFound?: string,
    init?: string
  },
  muiTheme?: ThemeOptions,
  analytic?: {
    background: string,
    leftPaneStyle?: CSSProperties,
    menuItemStyle?: CSSProperties
  },
  setting?: {
    menuItemStyle?: CSSProperties
  },
  colors?: {
    primary?: string[],
    contrastPrimary?: string[]
  },
  head?: {
    text: string,
    icon: string
  },
  header?: {
    logo?: string,
    InboxIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>,
    AnalyticsIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>,
    BroadcastIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>,
    broadcastIconUrl?: string,
    logoStyle?: CSSProperties,
    styleProfileIcon?: CSSProperties,
    background?: string,
    menuActiveColor?: string,
    menuColor?: string,
    menuActiveBackground?: string,
    menuBorderRight?: string
  },
  login?: {
    logoUrl: string,
    button?: {
      background: string
    }
  },
  broadcast?: {
    background: string
  },
  dataTableTheme?: Partial<DTTheme>,
  botStudio?: {
    collapsableLogo: string,
    collapsableStyle: CSSProperties,
    expandedLogo: string,
    expandedStyle: CSSProperties
  },
  componentStyle?: {
    borderRadius?: {
      xs: string,
      sm: string
    } | undefined
  },
  instagramRedirect?: {
    logoUrl: string
  }
}