import { useState, useContext, createContext, FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { GlobalUser, UserType } from '../../types'

const LoggedInUserContext = createContext<User | null>(null)
const LoggedInUserSetterContext = createContext<Dispatch<SetStateAction<User | null>>>(() => null)

type Props = {
  children: ReactNode
}

type UserV2 = UserType & {
  _id: string,
  groupId: string[]
}

type User = Partial<UserV2> & GlobalUser

const LoggedInUserContextProvider: FC<Props> = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null)

  return (
    <LoggedInUserContext.Provider value={loggedInUser}>
      <LoggedInUserSetterContext.Provider value={setLoggedInUser}>
        {children}
      </LoggedInUserSetterContext.Provider>
    </LoggedInUserContext.Provider>
  )
}


export function useLoggedInUser(): [User | null, Dispatch<SetStateAction<User | null>>] {
  const valueContext = useContext(LoggedInUserContext)
  const setterContext = useContext(LoggedInUserSetterContext)

  if (valueContext === undefined || setterContext === undefined) {
    throw 'useLoggedInUser must be used on child component of LoggedInUserContextProvider component'
  }

  return [valueContext, setterContext]
}

export default LoggedInUserContextProvider