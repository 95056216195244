import Request from '@bahasa-ai/plugins-request'
import JSCookie from 'js-cookie'
import { notifySentry } from '.'
import getEnv from './getEnv'
import pkg from '../../package.json'
import { removeCookies } from '@/components/utils/Auth'
import { universalRedirect } from '@/components/utils'

// This singleton object used for API v3

const { version } = pkg

function SingletonFondasiAPI() {
  let baseURL: string | null = null
  let token: string | null = null
  let config = {
    ssr: false
  }
  let application: string | string[] | undefined  = undefined

  const getToken = () => {
    return `Bearer ${JSCookie.get('authorization') || token}`
  }

  const APICall = async (method: string, path: string, payload?: any, otherParams?: Request['appConfig']) => {
    if (!baseURL) {
      throw 'You need to call setBaseURL(baseURL: string) first before using Fondasi API'
    }
    const { headers, ...params } = otherParams || {}

    let response: any = null
    try {
      const req = new Request(baseURL, {
        Authorization: getToken(),
        'Fondasi-Referrer': 'Web',
        'webapp-version': version,
        ...headers
      }, {
        timeout: 30000,
        ...params
      }, {
        shouldResetTimeout: true,
        returnData: false,
        retries: 5,
        retryDelay: () => 2000
      })
      if (method === 'get') {
        response = await req.get(path)
      } else if (method === 'post') {
        response = await req.post(path, payload)
      } else if (method === 'put') {
        response = await req.put(path, payload)
      } else if (method === 'patch') {
        response = await req.patch(path, payload)
      } else if (method === 'delete') {
        response = await req.delete(path, payload)
      }
      // something weird happen, non 200-ish API response
      if (!response) {
        console.log('Req: ', `${method} ${baseURL}${path} ${JSON.stringify(payload)}`)
        response ? console.log({ status: response.status, data: response.data, headers: response.headers }) : console.log('response undefined')

        return { status: 408, data: 'Timeout 30000ms', error: 'Timeout' }
      } else if (response.status < 200 || response.status >= 300) {
        if (response.status >= 500) {
          throw new Error(`[${response.status}]: ${method.toString().toUpperCase()} ${baseURL}${path}`)
        }
        return { status: response.status, data: response.data, error: response && (response.data.error?.message || response.data.error?.msg || response.data.error || response.data || 'Network Error') }
      }
    } catch (e) {
      if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        console.log('Req: ', `${method} ${baseURL}${path} ${JSON.stringify(payload)}`)
        response ? console.log({ status: response.status, data: response.data, headers: response.headers }) : console.log('response undefined')
        notifySentry(typeof window === 'undefined', e, {
          method,
          url: `${baseURL}${path}`,
          headers: {
            ...otherParams?.headers,
            Authorization: token
          },
          data: payload
        })
      }
      if (path !=='/requestToken') {
        if (response?.status === 401) {
          removeCookies()
          universalRedirect(null, '/login')
        }
      }

      return { status: response && response.status || 500, data: 'Network Error', error: response && (response.data.error?.message || response.data.error?.msg || response.data.error || response.data || 'Network Error') }
    }

    return { status: response.status, data: response.data, headers: response.headers }
  }

  const setBaseURL = (newBaseURL: string, newApplication?: string | string[] | undefined, initConfig?: any) => {
    baseURL = newBaseURL
    application = newApplication

    if (initConfig !== undefined) {
      config = {
        ...Object.keys(config)
          .reduce((acc, c) => initConfig[c] !== undefined ? { ...acc, [c]: initConfig[c] } : { ...acc }, { ...config })
      }
    }

    return baseURL
  }

  const getGlobalUserDetails = async () => {
    return await APICall('get', '/user/me')
  }

  const getWorkspaceExists = async (slug: string) => {
    return await APICall('get', `/workspace/${slug}/check`)
  }

  const getBaseURL = (useApplication?: boolean) => application && useApplication? `${baseURL}/${application}` : baseURL

  const setToken = (newToken: string) => {
    token = newToken
  }

  const logout = async () => {
    return await APICall('patch', '/auth/logout')
  }


  return {
    logout,
    setBaseURL,
    getBaseURL,
    setToken,
    getGlobalUserDetails,
    getWorkspaceExists,
    getToken
  }

}

const FondasiAPIv3 = SingletonFondasiAPI()

export default FondasiAPIv3

