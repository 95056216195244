import { useState, useContext, createContext, Dispatch, SetStateAction, FC } from 'react'

type ContextValueType = boolean

interface Props {}


const SocketInitializationContext = createContext<ContextValueType>(false)
const SocketInitializationSetterContext = createContext<Dispatch<SetStateAction<ContextValueType>>>(() => null)

const SocketInitializationProvider: FC<Props> = ({ children }) => {
  const [socketInitialization, setSocketInitialization] = useState<ContextValueType>(false)

  return (
    <SocketInitializationContext.Provider value={socketInitialization}>
      <SocketInitializationSetterContext.Provider value={setSocketInitialization}>
        {children}
      </SocketInitializationSetterContext.Provider>
    </SocketInitializationContext.Provider>
  )
}

export function useSocketInitialization(): [ContextValueType, Dispatch<SetStateAction<ContextValueType>>] {
  const valueContext = useContext(SocketInitializationContext)
  const setterContext = useContext(SocketInitializationSetterContext)

  if (valueContext === undefined || valueContext === undefined) {
    throw 'useSocketInitialization must be used on child component of SocketInitializationProvider component'
  }

  return [valueContext, setterContext]
}

export default SocketInitializationProvider