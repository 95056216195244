import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export default publicRuntimeConfig


export const ENABLE_APM = publicRuntimeConfig['enableApm'] == '1'
export const APM_SERVER_URL = publicRuntimeConfig['enableApm'] == '1' ? publicRuntimeConfig['apmServerUrl'] || 'https://9564e9539a3648878e74685fb747b803.apm.ap-southeast-1.aws.found.io' : undefined
export const HIDE_BILLING_PRICE = publicRuntimeConfig['hideBillingPrice'] == '1'
export const ENABLE_FILE_GS = publicRuntimeConfig['enableFileGS'] === '1'
export const PUSH_MESSAGE_API_URL = publicRuntimeConfig['pushMessageAPIURL']
export const CLIENT = publicRuntimeConfig['client'] || 'bahasa'
export const INSTAGRAM_FACEBOOK_APP_ID = publicRuntimeConfig['instagramFacebookAppId']
export const MESSENGER_FACEBOOK_APP_ID = publicRuntimeConfig['messengerFacebookAppId']
export const FACEBOOK_APP_ID = publicRuntimeConfig['facebookAppId']
export const FACEBOOK_SDK_VERSION = publicRuntimeConfig['facebookSdkVersion']
export const WA_TRIAL_NUMBER = publicRuntimeConfig['waTrialNumber'] || '6288905451500'
export const GRANT_PASSWORD_CLIENT_ID = publicRuntimeConfig['grantPasswordClientId'] || '9e4b1582-af7d-400e-be22-d6c1eaf469a6'
export const BASE_API_URL = publicRuntimeConfig['fondasiAPIBaseURL']
export const AIRTABLE_API_KEY = publicRuntimeConfig['airtableApiKey']
export const AIRTABLE_BASE_ID = publicRuntimeConfig['airtableBaseId']
export const CHATBOT_TEMPLATING_API = publicRuntimeConfig['chatbotTemplatingAPI']
export const SECRET_TOKEN_COPYWRITER = publicRuntimeConfig['secretTokenCopywriter']
export const LIMIT_PUSH_MESSAGE = Number(publicRuntimeConfig.limitPushMessage) || 10000
export const SHOW_INACTIVE_USERS = publicRuntimeConfig['showInactiveUsers'] === '1'
export const WEBAPP_URL = publicRuntimeConfig['WEBAPP_URL']