import pkg from '../../package.json'
import { notifySentry } from '.'
import Request from '@bahasa-ai/plugins-request'
import getEnv from './getEnv'
import { AxiosResponse, Method, ResponseType } from 'axios'
import { MessageType, UserType } from '../types'
import { Ticket } from '@/components/chat/LeftPane/CustomerListItem'
import FAQInterface from '@/types/FAQInterface'
import { TemplatePushMessage } from '@/components/chat/ChatPane/PushTemplateMessage'
import { removeCookies } from '@/components/utils/Auth'
import { universalRedirect } from '@/components/utils'

const { version } = pkg
// Layan API singleton object
// Need to be initialized in the root-top component
// Set base URL before using it, otherwise will throw error

// This singleton object used for API v1

type APICallType = <Data = any>(method: Method, path: string, payload?: any, authorization?: string, headers?: any, responseType?: ResponseType) => Promise<{
  status: number,
  data?: Data,
  error?: any,
  headers?: any
}>
function SingletonLayanAPI() {
  let baseURL: string = ''
  let config = {
    ssr: false
  }

  const APICall: APICallType = async (method, path, payload, authorization, headers, responseType)  => {
    if (!baseURL) {
      throw 'You need to call setBaseURL(baseURL: string) first before using LayanAPI'
    }

    let response: null | AxiosResponse<any> | undefined = null
    try {
      const req = new Request(baseURL, {
        ...headers,
        'Fondasi-Referrer': 'Web',
        'webapp-version': version,
        Authorization: authorization
      }, {
        timeout: 30000,
        responseType: responseType
      }, {
        shouldResetTimeout: true,
        returnData: false,
        retries: 5,
        retryDelay: () => 2000
      })
      if (method === 'get') {
        response = await req.get(path)
      } else if (method === 'post') {
        response = await req.post(path, payload)
      } else if (method === 'put') {
        response = await req.put(path, payload)
      } else if (method === 'patch') {
        response = await req.patch(path, payload)
      } else if (method === 'delete') {
        response = await req.delete(path, payload)
      }
      // console.log('Req: ', `${method} ${baseURL}${path} ${JSON.stringify(payload)}`)
      // response ? console.log({ status: response.status, data: response.data, headers: response.headers }) : console.log('response undefined')

      // something weird happen, non 200-ish API response
      if (!response) {
        return { status: 408, data: 'Timeout 30000ms', error: 'Timeout' }
      } else if (response.status < 200 || response.status >= 300) {
        if (response.status >= 500) {
          throw new Error(`[${response.status}]: ${method.toString().toUpperCase()} ${baseURL}${path}`)
        }
        return { status: response.status, data: response.data, error: response.data.error ? response.data.error.message || response.data.error.msg || response.data.error : response.data || 'Network Error' }
      }
    } catch (e) {
      if (getEnv['environment'] !== 'local' && getEnv['layanWebSentryDSN']) {
        notifySentry(typeof window === 'undefined', e, {
          method,
          url: `${baseURL}${path}`,
          headers: {
            ...headers,
            Authorization: authorization
          },
          data: payload
        })
      }
      if (path !=='/requestToken') {
        if (response?.status === 401) {
          removeCookies()
          universalRedirect(null, '/login')
        }
      }

      return { status: response && response.status || 500, data: 'Network Error', error: response?.data.error ? response.data.error.message || response.data.error.msg || response.data.error || 'Network Error' : response?.data || 'Network Error' }
    }

    return { status: response.status, data: response.data, headers: response.headers }
  }

  const setBaseURL = (newBaseURL: string, initConfig?: any) => {
    baseURL = newBaseURL

    if (initConfig !== undefined) {
      config = {
        ...Object.keys(config)
          .reduce((acc, c) => initConfig[c] !== undefined ? { ...acc, [c]: initConfig[c] } : { ...acc }, { ...config })
      }
    }

    return baseURL
  }

  const getBaseURL = () => baseURL

  const login = async (username: string, password: string) => {
    return await APICall<{ user: UserType, jwt: any }>('post', '/requestToken', { username, password })
  }

  const setAgentStatus = async (isOnline: boolean, name: string, jwtToken: string | undefined) => {
    return await APICall('patch', '/user', { user: { isAvailable: isOnline, status: name } }, `Bearer ${jwtToken}`)
  }

  const getUserDetails = async (jwtToken: string | undefined) => {
    return await APICall('get', '/user', {}, `Bearer ${jwtToken}`)
  }

  const patchSelfUser = async (userData: Partial<UserType>, jwtToken: string | undefined) => {
    return await APICall('patch', '/user', { user: { ...userData } }, `Bearer ${jwtToken}`)
  }

  const patchSelfPassword = async (newPassword: string, jwtToken: string | undefined) => {
    return await APICall('patch', '/user', { user: { password: newPassword } }, `Bearer ${jwtToken}`)
  }

  const getCustomerDetail = async (customerId: string | number, jwtToken: string | undefined) => {
    return await APICall('get', `/user/${customerId}`, {}, `Bearer ${jwtToken}`)
  }

  const getAssignedRoom = async (latestTicketStatus: string, skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/rooms/assignedTo/me?latestTicketStatus=${latestTicketStatus}&skip=${skip}&limit=${limit}&sortBy=desc`, {}, `Bearer ${jwtToken}`)
  }

  const getRoomTickets = async (roomId: string, jwtToken: string | undefined, skip?: number, limit?: number, sort?: string) => {
    return await APICall('get', `/room/${roomId}/tickets?skip=${skip}&limit=${limit}&sort=${sort}`, {}, `Bearer ${jwtToken}`)
  }

  const getTicketFields = async (jwtToken: string | undefined) => {
    return await APICall('get', '/fields', {}, `Bearer ${jwtToken}`)
  }

  const updateTicket = async (ticketId: string, payload: { ticket: Partial<Ticket> }, jwtToken) => {
    return await APICall('patch', `/ticket/${ticketId}`, payload, `Bearer ${jwtToken}`)
  }

  const solveTicket = async (ticketId: string, jwtToken: string | undefined) => {
    return await APICall('post', `/solve/${ticketId}`, {}, `Bearer ${jwtToken}`)
  }

  const getTicket = async (ticketId: string, jwtToken: string | undefined) => {
    return await APICall('get', `/ticket/${ticketId}`, {}, `Bearer ${jwtToken}`)
  }

  const getTicketConversation = async (ticketId: string, jwtToken: string | undefined, skip?: number, limit?: number, sort?: string) => {
    return await APICall('get', `/ticket/${ticketId}/messages?${skip ? `skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}${sort ? `&sort=${sort}` : '&sort=asc'}`, {}, `Bearer ${jwtToken}`)
  }

  const getMacros = async (jwtToken: string | undefined) => {
    return await APICall('get', '/templateMessages', {}, `Bearer ${jwtToken}`)
  }

  const createMacro = async (payload: { title: string, message: string }, jwtToken: string | undefined) => {
    return await APICall('post', '/templateMessage', { templateMessage: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const patchMacro = async (macroId: string, payload: { title?: string, message?: string }, jwtToken: string | undefined) => {
    return await APICall('patch', `/templateMessage/${macroId}`, { templateMessage: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const deleteMacro = async (macroId: string, jwtToken: string | undefined) => {
    return await APICall('delete', `/templateMessage/${macroId}`, {}, `Bearer ${jwtToken}`)
  }

  const getAgentStatuses = async (jwtToken: string | undefined) => {
    return await APICall('get', '/statuses', {}, `Bearer ${jwtToken}`)
  }

  const createAgentStatus = async (payload: { name: string, isAvailable: boolean }, jwtToken: string | undefined) => {
    return await APICall('post', '/status', { status: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const patchAgentStatus = async (statusId: string, payload: { name: string, isAvailable: boolean }, jwtToken: string | undefined) => {
    return await APICall('patch', `/status/${statusId}`, { status: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const deleteAgentStatus = async (statusId: string, jwtToken: string | undefined) => {
    return await APICall('delete', `/status/${statusId}`, {}, `Bearer ${jwtToken}`)
  }

  const uploadFile = async (file: FormData, filename: string, mimeType: string, jwtToken: string | undefined, channel?: string) => {
    return await APICall('post', `/file?filename=${encodeURIComponent(filename)}&mimetype=${encodeURIComponent(mimeType)}${channel ? `&channel=${channel}` : ''}`, file, `Bearer ${jwtToken}`, {
      'Content-Type': 'application/octet-stream'
    })
  }

  const getActivities = async (skip: number, limit: number, startedAt: string, endedAt: string, jwtToken: string | undefined) => {
    return await APICall('get', `/activities?startedAt=${startedAt}&endedAt=${endedAt}&skip=${skip}&limit=${limit}&sort=desc`, {}, `Bearer ${jwtToken}`)
  }

  const getActivitiesByUserId = async (userId: string | number, skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/activities?userId=${userId}&skip=${skip}&limit=${limit}&sort=desc`, {}, `Bearer ${jwtToken}`)
  }

  const getActivitiesByUserIdAndTime = async (userId: string | number, skip: number, limit: number, startedAt: string, endedAt: string, jwtToken: string | undefined) => {
    return await APICall('get', `/activities?startedAt=${startedAt}&endedAt=${endedAt}&userId=${userId}&skip=${skip}&limit=${limit}&sort=desc`, {}, `Bearer ${jwtToken}`)
  }

  const getUsersByRole = async (role: string, jwtToken: string | undefined, limited?: boolean) => {
    return await APICall('get', `/users?${role ? `role=${role}` : '' }${limited ? `&limited=${limited}` : '' }`, {}, `Bearer ${jwtToken}`)
  }

  const createUser = async (payload: Partial<UserType>, jwtToken: string | undefined) => {
    return await APICall('post', '/user', { user: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const updateUser = async (userId: string | number, payload: Partial<UserType>, jwtToken: string | undefined) => {
    return await APICall('patch', `/user/${userId}`, { user: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const deleteUser = async (userId: string | number, jwtToken: string | undefined) => {
    return await APICall('delete', `/user/${userId}`, {}, `Bearer ${jwtToken}`)
  }

  const getFaq = async (jwtToken: string | undefined) => {
    return await APICall('get', '/faqs', {}, `Bearer ${jwtToken}`)
  }

  const createFaq = async (payload: Partial<FAQInterface>, jwtToken: string | undefined) => {
    return await APICall('post', '/faq', { faq: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const updateFaq = async (faqId: string, payload: Partial<FAQInterface>, jwtToken: string | undefined) => {
    return await APICall('patch', `/faq/${faqId}`, { faq: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const deleteFaq = async (faqId: string, jwtToken: string | undefined) => {
    return await APICall('delete', `/faq/${faqId}`, {}, `Bearer ${jwtToken}`)
  }

  const updateStatusFaq = async (faqId: string | undefined, jwtToken: string | undefined) => {
    return await APICall('post', `/faqClientToggle${faqId ? `/${faqId}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const sendMessage = async (ticketId: string, payload: Partial<MessageType>, jwtToken: string | undefined) => {
    return await APICall('post', `/ticket/${ticketId}`, { message: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const getActivitiesDetail = async (agentId: string | number, startedAt: string, endedAt: string, jwtToken: string | undefined) => {
    return await APICall('get', `/activitiesDetail?startedAt=${startedAt}&endedAt=${endedAt}&agentId=${agentId}`, {}, `Bearer ${jwtToken}`)
  }

  const getCustomerNote = async (customerId: string, jwtToken: string | undefined) => {
    return await APICall('get', `/customerNote/${customerId}`, {}, `Bearer ${jwtToken}`)
  }

  const sendCustomerNote = async (customerId: string, payload: { description: string }, jwtToken: string | undefined) => {
    return await APICall('post', `/customerNote/${customerId}`, { customerNote: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const deleteCustomerNote = async (customerId: string, jwtToken: string | undefined) => {
    return await APICall('delete', `/customerNote/${customerId}`, {}, `Bearer ${jwtToken}`)
  }

  const getFaqCategories = async (jwtToken: string | undefined) => {
    return await APICall('get', '/faq/categories', {}, `Bearer ${jwtToken}`)
  }

  const createFaqCategory = async (payload: Record<string, any>, jwtToken: string | undefined) => {
    return await APICall('post', '/faq/category', { category: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const patchFaqCategory = async (categoryId: string, payload: Record<string, any>, jwtToken: string | undefined) => {
    return await APICall('patch', `/faq/category/${categoryId}`, { category: { ...payload } }, `Bearer ${jwtToken}`)
  }

  const deleteFaqCategory = async (categoryId: string, jwtToken: string | undefined) => {
    return await APICall('delete', `/faq/category/${categoryId}`, {}, `Bearer ${jwtToken}`)
  }

  const getRoomsInQueue = async (jwtToken: string | undefined) => {
    return await APICall('get', '/rooms/queue', {}, `Bearer ${jwtToken}`)
  }

  const postAssignedRoom = async (ticketId: string | undefined, isNew: boolean, jwtToken: string | undefined) => {
    return await APICall('post', `/assign/${ticketId}/me${isNew ? '?new=true' : ''}`, {}, `Bearer ${jwtToken}`)
  }

  // get list conversation of one room
  const getRoomConversation = async (roomId: string | number, skip: number | null, limit: number | null, jwtToken: string | undefined, sessionId?: string, sourceId?: string, agentId?: string) => {
    return await APICall('get', `/conversations?integration=wa${roomId ? `&roomId=${roomId}` : ''}&skip=${skip}&limit=${limit}&sort=desc${sessionId ? `&sessionId=${sessionId}` : ''}${sourceId ? `&sourceId=${sourceId}${agentId ? `&agentId=${agentId}` : ''}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getConversationRooms = async (startedAt: string, endedAt: string, sourceId: string | null, status: string | undefined | boolean, skip: number, limit: number, jwtToken: string | undefined, integration?: string | boolean) => {
    return await APICall('get', `/conversation/rooms?startedAt=${startedAt}&endedAt=${endedAt}${sourceId ? `&sourceId=${sourceId}` : ''}${status ? `&status=${status}` : ''}${skip !== null ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}${integration ? `&integration=${integration}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getRooms = async (startedAt: string, endedAt: string, status: string | undefined, skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/rooms?${startedAt ? `startedAt=${startedAt}` : ''}${endedAt ? `&endedAt=${endedAt}` : ''}${status ? `&status=${status}` : ''}${skip !== null ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getRoomById = async (roomId: string, jwtToken: string | undefined) => {
    return await APICall('get', `/room/${roomId}`, {}, `Bearer ${jwtToken}`)
  }

  const getRoomsByAgent = async (agentId: string | number, startedAt: string, endedAt: string, latestTicketStatus: string | undefined, skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/rooms/assignedTo/${agentId}?fromAllTickets=true${latestTicketStatus ? `&latestTicketStatus=${latestTicketStatus}` : ''}${startedAt ? `&startedAt=${startedAt}` : ''}${endedAt ? `&endedAt=${endedAt}` : ''}${skip !== null ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}&sortBy=desc`, {}, `Bearer ${jwtToken}`)
  }

  const getRoomsByAgentGroup = async (agentGroupId: string, startedAt: string, endedAt: string, latestTicketStatus: string | undefined, skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/rooms/assignedTo/group/${agentGroupId}?fromAllTickets=true${latestTicketStatus ? `&latestTicketStatus=${latestTicketStatus}` : ''}${startedAt ? `&startedAt=${startedAt}` : ''}${endedAt ? `&endedAt=${endedAt}` : ''}${skip !== null ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}&sortBy=desc`, {}, `Bearer ${jwtToken}`)
  }

  const getTickets = async (number: number, jwtToken: string | undefined) => {
    return await APICall('get', `/tickets?${number ? `number=${number}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getTicketsByTag = async (tag: string, assignedTo: string | undefined | boolean, status: string | undefined | boolean, startedAt: string, endedAt: string, skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/tickets?tags=${encodeURIComponent(tag)}&${startedAt ? `startedAt=${startedAt}` : ''}${endedAt ? `&endedAt=${endedAt}` : ''}${assignedTo ? `&assignedTo=${assignedTo}` : ''}${status ? `&status=${status}` : ''}${skip ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const getRoomByInitiatedBy = async (source: string, jwtToken: string | undefined) => {
    return await APICall('get', `/room/initiatedBy/${source}`, {}, `Bearer ${jwtToken}`)
  }

  const getTemplateFaq = async (jwtToken: string | undefined) => {
    return await APICall('get', '/importFaqTemplate?type=xlsx', {}, `Bearer ${jwtToken}`, {}, 'blob')
  }

  const importFaq = async (file: FormData, jwtToken: string | undefined) => {
    return await APICall('post', '/bulkCreateFaq', file, `Bearer ${jwtToken}`, {
      'Content-Type': 'multipart/form-data'
    })
  }

  const requeue = async (ticketId: string, jwtToken: string | undefined) => {
    return await APICall('post', `/requeue/${ticketId}`, {}, `Bearer ${jwtToken}`)
  }

  const postReassignTicketToUserAgent = async (ticketId: string, userId: string | number, jwtToken: string | undefined) => {
    return await APICall('post', `/assign/${ticketId}/${userId}`, {}, `Bearer ${jwtToken}`)
  }

  const getChannels = async (jwtToken: string | undefined) => {
    return await APICall('get', '/bots', {}, `Bearer ${jwtToken}`)
  }

  const getTemplatePushMessages = async (ticketId: string, jwtToken: string | undefined) => {
    return await APICall('get', `/templatePushMessages/${ticketId}`, {}, `Bearer ${jwtToken}`)
  }

  const sendTemplatePushMessage = async (roomId: string, message: TemplatePushMessage | undefined, jwtToken: string | undefined) => {
    return await APICall('post', `/notify/pushReminder/${roomId}`, { templateMessage: message }, `Bearer ${jwtToken}`)
  }

  const getSatisfaction = async (startedAt: string, endedAt: string, limit: number, skip: number, tags: string | null, jwtToken: string | undefined) => {
    return await APICall('get', `/satisfactions?startedAt=${startedAt}&endedAt=${endedAt}&limit=${limit}&skip=${skip}${tags ? `&tags=${encodeURIComponent(tags)}` : ''}`, {}, `Bearer ${jwtToken}`)
  }

  const exportFaqs = async (type: string, jwtToken: string | undefined) => {
    return await APICall('get', `/exportFaq?type=${type || 'csv'}`, {}, `Bearer ${jwtToken}`, {}, 'blob')
  }

  const getTicketsByListId = async (listTicketId: string[], jwtToken: string | undefined) => {
    return await APICall('post', '/ticket/byListId', { ticketListId: listTicketId }, `Bearer ${jwtToken}`)
  }

  const getAllPushToken = async (skip: number, limit: number, jwtToken: string | undefined) => {
    return await APICall('get', `/pushToken?skip=${skip}&limit=${limit}`, { }, `Bearer ${jwtToken}`)
  }

  const continueToDMIG = async (ticketId: number | string, jwtToken: string | undefined) => {
    return await APICall('get', `/ticket/continue-dm/${ticketId}`, { }, `Bearer ${jwtToken}`)
  }

  return {
    continueToDMIG,
    getRoomsByAgentGroup,
    getBaseURL,
    setBaseURL,
    getCustomerDetail,
    patchSelfUser,
    patchSelfPassword,
    getAssignedRoom,
    getRoomTickets,
    getTicketFields,
    setAgentStatus,
    getAgentStatuses,
    createAgentStatus,
    patchAgentStatus,
    deleteAgentStatus,
    getTicket,
    getMacros,
    createMacro,
    patchMacro,
    deleteMacro,
    login,
    updateTicket,
    solveTicket,
    getUserDetails,
    uploadFile,
    getTicketConversation,
    getActivities,
    getActivitiesByUserId,
    getUsersByRole,
    createUser,
    updateUser,
    deleteUser,
    getFaq,
    createFaq,
    updateFaq,
    deleteFaq,
    updateStatusFaq,
    sendMessage,
    getActivitiesDetail,
    getCustomerNote,
    sendCustomerNote,
    deleteCustomerNote,
    getFaqCategories,
    createFaqCategory,
    patchFaqCategory,
    deleteFaqCategory,
    getRoomsInQueue,
    postAssignedRoom,
    getRoomConversation,
    getConversationRooms,
    getRooms,
    getRoomById,
    getRoomsByAgent,
    getTickets,
    getRoomByInitiatedBy,
    getTemplateFaq,
    importFaq,
    requeue,
    postReassignTicketToUserAgent,
    getChannels,
    getTemplatePushMessages,
    sendTemplatePushMessage,
    getSatisfaction,
    exportFaqs,
    getTicketsByListId,
    getAllPushToken,
    getTicketsByTag,
    getActivitiesByUserIdAndTime
  }
}

const LayanAPI = SingletonLayanAPI()

export default LayanAPI