import { ApplicationType, UserType } from '@/types/index'
import FondasiAPIv3 from '@/utils/FondasiAPIv3'
import * as Sentry from '@sentry/nextjs'
import JSCookie from 'js-cookie'
import { NextPage } from 'next'
import NextCookies from 'next-cookies'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'
import { universalRedirect } from '.'
import FondasiAPI from '../../utils/FondasiAPI'
import LayanSocket from '../../utils/LayanSocket'
import { useApplication } from './ApplicationProvider'
import { useLoggedInUser } from './LoggedInUserProvider'
import getEnv from '../../utils/getEnv'

export function saveLoginTokenToCookie(jwtToken: string): void {
  FondasiAPI.setToken(jwtToken)
  JSCookie.set('authorization', jwtToken, { expires: 7 })
}

export const synchronizedLogout = async (_url?: string): Promise<void> =>  {
  // set agent status to offline
  await FondasiAPI.setAgentStatus(false, 'Offline')
  await FondasiAPIv3.logout()

  // remove cookie 'authorization' from browser
  JSCookie.remove('authorization')

  // trigger 'logout' key event so event listener can trigger syncLogout on any active tab
  window.localStorage.setItem('logout', Date.now().toString())

  const _application = window.localStorage.getItem('application')

  // remove all local storage used by app
  window.localStorage.clear()

  // checking first for preventing throw error
  if (LayanSocket.isInitialized()) {
    // close socket connection on this namespace
    try {
      LayanSocket.close()
    } catch (error) {
      console.log('%j', error)
    }
  }

  // // redirect to login
  universalRedirect(null, '/login', '/login')
}

export const removeCookies = (): void => {
  JSCookie.remove('authorization')
  JSCookie.remove('authorized')
  JSCookie.remove('loggedIn')
}

export interface AuthProps {
  loggedInUserData?: UserType & { authorization: string },
  applicationDetail?: ApplicationType
}

export interface WithAuthChecking extends AuthProps {

}

export function withAuthChecking<T = WithAuthChecking>(Component: NextPage<T | AuthProps>, _pageName?: string, roles?: string[]): FC {
  const AuthCheckingComponent: NextPage<AuthProps> = (props) => {
    const router = useRouter()
    const [loggedInUser, setLoggedInUser] = useLoggedInUser()
    const [_application, setApplication] = useApplication()
    const { application } = router.query

    const syncLogout = event => {
      if (event.key === 'logout') {
        console.log('An active tab just triggering sync logout... Logout from this tab too')
        universalRedirect(null, '/login', '/login')
      }
    }

    useEffect(() => {
      if (props.loggedInUserData && props.applicationDetail) {
        saveLoginTokenToCookie(props.loggedInUserData.authorization)
        const groupIds = props.loggedInUserData.agentGroups?.filter(g => g.group?.id).map(g => String(g.group?.mongoId || g.group?.id))
        setLoggedInUser({ ...loggedInUser, ...props.loggedInUserData, _id: props.loggedInUserData.mongoId || String(props.loggedInUserData.id), groupId: groupIds })
        setApplication({ ...props.applicationDetail })
        if (roles && roles.length !== 0 && !roles.includes(props.loggedInUserData.role)) {
          universalRedirect(null, `/${application}/chat`, '/[application]/chat')
        }
        window.localStorage.setItem('application', application as string)
      }

      // event listener for sync logout
      // lostening for event with key 'logout' from storage
      window.addEventListener('storage', syncLogout)

      // clean up the listener
      return () => {
        window.removeEventListener('storage', syncLogout)
        window.localStorage.removeItem('logout')
      }
    }, [])

    // return wrapped component with it's props and injected props
    // at this point, user is authenticated and eligible to access the component
    return (
      <Component {...props} loggedInUserData={props.loggedInUserData} />
    )
  }

  // Don't use redirect here. Redirect is centralized on middleware
  // only log them if not found, because it something wrong on our middleware logic
  AuthCheckingComponent.getInitialProps = async (context) => {
    let componentProps = {}
    try {
      console.log(`Requested page: ${Component.displayName || Component.name || 'Component'}`)
      console.log('AuthComponent.getInitialProps try:')

      // get jwt token here
      const { authorization } = NextCookies(context)
      const { application } = context.query

      FondasiAPIv3.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v3`, application)
      FondasiAPI.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v2/${application}`)

      // // check if jwt token exist
      if (!authorization) {
        console.log('not authenticated')
        return {}
      }
      FondasiAPI.setToken(authorization)
      FondasiAPIv3.setToken(authorization)

      // check if jwt token valid -> get user to backend
      console.log('getUserDetails from AuthCheckingComponent...')

      const globalUserDetails  = await FondasiAPIv3.getGlobalUserDetails()
      if (globalUserDetails.status < 200 || globalUserDetails.status >= 300) {
        console.log('not authenticated')
        return {}
      }

      const getUserDetails = await FondasiAPI.getUserDetails()
      if (getUserDetails.status < 200 || getUserDetails.status >= 300) {
        console.log('failed getuserdetails')
        return {}
      } else {
        // get wrapped component props
        const getApplicationDetail = await FondasiAPI.getApplicationDetail()

        // failed get Application data, try to login again
        if (getApplicationDetail.status < 200 || getApplicationDetail.status >= 300) {
          console.log('failed getApplicationDetail')
          return {}
        }

        componentProps = Component.getInitialProps && await Component.getInitialProps(context) || {}
        return { ...componentProps, loggedInUserData: { ...globalUserDetails.data?.['globalUser'], ...getUserDetails.data['user'], authorization }, applicationDetail: { ...getApplicationDetail.data.application } }
      }
    } catch(e) {
      console.log('AuthComponent.getInitialProps catch: ')
      console.log(e)
      Sentry.captureException(e)

      return { ...componentProps }
    }
  }

  return AuthCheckingComponent
}

export function withAuthGlobalChecking<T = WithAuthChecking>(Component: NextPage<T | AuthProps>, _pageName?: string, _roles?: string[]): FC {
  const AuthCheckingComponent: NextPage<AuthProps> = (props) => {
    const [loggedInUser, setLoggedInUser] = useLoggedInUser()

    const syncLogout = event => {
      if (event.key === 'logout') {
        console.log('An active tab just triggering sync logout... Logout from this tab too')
        universalRedirect(null, '/login', '/login')
      }
    }

    useEffect(() => {
      if (props.loggedInUserData) {
        const groupIds = props.loggedInUserData.agentGroups?.filter(g => g.group?.id).map(g => String(g.group?.mongoId || g.group?.id))
        setLoggedInUser({ ...loggedInUser, ...props.loggedInUserData, _id: props.loggedInUserData.mongoId || String(props.loggedInUserData.id), groupId: groupIds })
      }

      // event listener for sync logout
      // lostening for event with key 'logout' from storage
      window.addEventListener('storage', syncLogout)

      // clean up the listener
      return () => {
        window.removeEventListener('storage', syncLogout)
        window.localStorage.removeItem('logout')
      }
    }, [])

    // return wrapped component with it's props and injected props
    // at this point, user is authenticated and eligible to access the component
    return (
      <Component {...props} loggedInUserData={props.loggedInUserData} />
    )
  }

  // Don't use redirect here. Redirect is centralized on middleware
  // only log them if not found, because it something wrong on our middleware logic
  AuthCheckingComponent.getInitialProps = async (context) => {
    const componentProps = {}
    try {
      console.log(`Requested page: ${Component.displayName || Component.name || 'Component'}`)
      console.log('GlobalAuthComponent.getInitialProps try:')

      // get jwt token here
      const { authorization } = NextCookies(context)
      const { application } = context.query

      FondasiAPIv3.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v3`, application)
      FondasiAPI.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v2/${application}`)

      // check if jwt token exist
      if (!authorization) {
        console.log('not authenticated')
        return {}
      }
      FondasiAPI.setToken(authorization)
      FondasiAPIv3.setToken(authorization)

      const globalUserDetails  = await FondasiAPIv3.getGlobalUserDetails()
      if (globalUserDetails.status < 200 || globalUserDetails.status >= 300) {
        console.log('failed get globalUserDetails')
        return {}
      }

      return { ...componentProps, loggedInUserData: { ...globalUserDetails.data?.['globalUser'] } }
    } catch(e) {
      console.log('GlobalAuthComponent.getInitialProps catch: ')
      console.log(e)
      Sentry.captureException(e)

      return { ...componentProps }
    }
  }

  return AuthCheckingComponent
}